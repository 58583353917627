import { createContext } from 'react';

import { FeatureAppEnvironment } from '@feature-hub/core';

import { FeatureAppConfig, FeatureAppDependencies } from '..';
import { useSafeContext } from './use-safe-context';

export type CurrentFeatureAppEnvironment = FeatureAppEnvironment<
  FeatureAppDependencies,
  FeatureAppConfig
>;

export const FeatureAppEnvironmentContext = createContext<CurrentFeatureAppEnvironment | null>(null);

export function useFeatureAppEnvironment(): CurrentFeatureAppEnvironment {
  const featureAppEnvironment = useSafeContext(FeatureAppEnvironmentContext, 'FeatureAppEnvironmentContext');

  return featureAppEnvironment;
}
