import { createContext } from 'react';
import { FeatureAppDependencies } from '..';
import { useSafeContext } from './use-safe-context';

export const FeatureServicesContext = createContext<FeatureAppDependencies | null>(null);

export function useFeatureServices(): FeatureAppDependencies {
  const featureServices = useSafeContext(FeatureServicesContext, 'FeatureServicesContext');

  return featureServices;
}
