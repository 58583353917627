import React from 'react';

import {Container} from '@vw-marketing/us-components';

import {StoryStream} from '../components/storystream/StoryStream';

export const App = () => {
  return (
    <Container stretchContent>
      <StoryStream />
    </Container>
  );
};
