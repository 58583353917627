import { createContext } from 'react';

import { StoryStreamContextType } from '../typings/storystream-app';
import { useSafeContext } from './use-safe-context';

export const StoryStreamContext = createContext<StoryStreamContextType | null>(null);

export function useStorystreamContext(): StoryStreamContextType {
  const storyStreamContext = useSafeContext(StoryStreamContext, 'StoryStreamContext');

  return storyStreamContext;
}
