import { Infer, is, object, string } from 'superstruct';

import { useFeatureServices } from './use-feature-services';

const Content = object({
  storyStreamAppId: string(),
  storyStreamBaseUrl: string(),
})

export type FeatureAppContent = Infer<typeof Content>;

// const carouselId: string = 'MDhkMzlhYzA2NTlkZGNjNTll.js';
const contentHubId: string = 'OWU0MjYyN2QwNGNhYWVkNjg4.js';
// const mosaicId: string = 'ZmRhNzcxYjAzZjk5MzU2MDMw.js';

const DEFAULT_CONTENT = {
  storyStreamAppId: contentHubId,
  storyStreamBaseUrl: 'https://apps.storystream.ai/app/js/',
}

export const useFeatureAppContent = (): FeatureAppContent => {
  const { 'fa-content-service': contentService } = useFeatureServices();
  const content = contentService?.content;

  return is(content, Content) ? content : DEFAULT_CONTENT
}
