import { styled } from "@volkswagen-onehub/components-core";

export const StyledStryStreamContainer: any = styled.div`
`;

export const StyledSpinnerContainer: any = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    path:nth-child(2) {
      stroke: #001e50;
    }
  }
`;
