import React, { PropsWithChildren, useState } from 'react';
import { useFeatureServices } from '../hooks/use-feature-services';
import { LangType } from '../typings/lang';
import { StoryStreamContextType } from '../typings/storystream-app';
import { translation } from '../lang/translation';
import { StoryStreamContext } from '../hooks/use-storystream-context';

export const StoryStreamContextProvider = (props: PropsWithChildren) => {
  const { 'locale-service': localeService } = useFeatureServices();
  const localeLang = localeService.language as LangType;

  const [lang, setLang] = useState<LangType>(localeLang);

  const translationHandler = (key: string): string => {
    return translation(lang, key);
  };

  const storyStreamContextValue: StoryStreamContextType = {
    translationHandler,
    lang: {
      value: lang,
      set: setLang,
    },
  };

  return (
    <StoryStreamContext.Provider value={storyStreamContextValue}>
      {props.children}
    </StoryStreamContext.Provider>
  );
};
