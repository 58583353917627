import {History} from 'history';
import { createContext } from 'react';
import { useSafeContext } from './use-safe-context';

export const HistoryContext = createContext<History | null>(null);

export function useHistory(): History {
  const history = useSafeContext(HistoryContext, 'HistoryContext');

  return history;
}
