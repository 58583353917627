import React, {useEffect} from 'react';

import {Spinner} from '@volkswagen-onehub/components-core';

import {CreateScripTag} from '../../utils/create-script-tag';
import {StyledSpinnerContainer, StyledStryStreamContainer} from './styles';
import {useFeatureAppContent} from '../../hooks/use-feature-app-content';

export const StoryStream = () => {
  const {storyStreamAppId, storyStreamBaseUrl} = useFeatureAppContent();

  useEffect(() => {
    CreateScripTag(storyStreamBaseUrl, storyStreamAppId)
      .then(() => console.log('Success loading story stream'))
      .catch((error) => console.error(`Error loading story stream: ${error}`));
  }, [storyStreamAppId, storyStreamBaseUrl]);

  return (
    <StyledStryStreamContainer id="stry-wrapper">
      <StyledSpinnerContainer>
        <Spinner variant="x-large" />
      </StyledSpinnerContainer>
    </StyledStryStreamContainer>
  );
};
