import { useContext } from "react";

export function useSafeContext<TContext>(
  context: React.Context<TContext | null>,
  contextName: string,
): TContext {
  const contextValue = useContext(context);

  if (!contextValue) throw new Error(`No ${contextName} value was provided.`);

  return contextValue;
}
