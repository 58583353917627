export const CreateScripTag = (baseUrl: string, appId: string) => {
  return new Promise((resolve, reject) => {
    const scriptTag = document.createElement('script');
    const src: string = baseUrl + appId;

    scriptTag.type = 'text/javascript';
    scriptTag.src = src;
    scriptTag.async = true;
    scriptTag.onload = () => resolve(scriptTag);
    scriptTag.onerror = () => reject(new Error(`Script load error for ${src}`));

    document.body.appendChild(scriptTag);
  })
}
