import React from 'react';

import {AsyncSsrManagerV1} from '@feature-hub/async-ssr-manager';
import {FaContentServiceV1} from '@volkswagen-onehub/fa-content-service';
import {FeatureAppDefinition, FeatureServices, Logger} from '@feature-hub/core';
import {FeatureappServiceV1} from '@volkswagen-onehub/featureapp-service';
import {HistoryServiceV1} from '@feature-hub/history-service';
import {LocaleServiceV1} from '@volkswagen-onehub/locale-service';
import {ReactFeatureApp} from '@feature-hub/react';
import {SerializedStateManagerV1} from '@feature-hub/serialized-state-manager';
import {ServerRequestV1} from '@feature-hub/server-request';
import {ServiceConfigProviderV1} from '@volkswagen-onehub/service-config-provider';
import {styled} from '@vw-marketing/us-components';
import {ThemeProvider} from '@vw-marketing/us-components';
import {TrackingManagerV1} from '@volkswagen-onehub/tracking-service';

import {App} from './app/App';
import {FeatureAppEnvironmentContext} from './hooks/use-feature-app-environment';
import {FeatureAppMemoryStoreV1, featureAppMemoryStoreDefinition} from './hooks/feature-app-memory-store';
import {FeatureServicesContext} from './hooks/use-feature-services';
import {HistoryContext} from './hooks/use-history';
import {StoreModel} from './hooks-store/typings/store';
import {StoryStreamContextProvider} from './context/StoryStreamContext';

declare var __VERSION__: String;

export interface FeatureAppDependencies extends FeatureServices {
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:history': HistoryServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 's2:server-request'?: ServerRequestV1;
  readonly 'fa-content-service'?: FaContentServiceV1;
  readonly 'featureapp-service': FeatureappServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'service-config-provider': ServiceConfigProviderV1;
  readonly 'feature-app-memory-store': FeatureAppMemoryStoreV1<StoreModel>;
  readonly 'tracking'?: TrackingManagerV1;
}

export type FeatureAppMode = 'default' | 'standalone' | 'module';

export interface FeatureAppConfig {
  readonly mode?: FeatureAppMode;
  readonly faServicesUrl: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly storyStreamAppId: string;
  readonly storyStreamBaseUrl: string;
}

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureAppDependencies,
  FeatureAppConfig
> = {
  dependencies: {
    featureServices: {
      's2:history': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'feature-app-memory-store': '^1.0.0',
      'featureapp-service': '^1.3.0',
      'locale-service': '^1.0.0',
      'service-config-provider': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:server-request': '^1.0.0',
      'tracking': '^1.0.0',
      'fa-content-service': '^1.0.0',
    },
  },
  ownFeatureServiceDefinitions: [featureAppMemoryStoreDefinition],

  create: (env) => {
    const {featureServices} = env;

    const {
      's2:async-ssr-manager': asyncSsrManager,
      's2:history': historyService,
      's2:serialized-state-manager': serializedStateManager,
      'feature-app-memory-store': memoryStore,
    } = featureServices;

    const history = asyncSsrManager
      ? historyService.createStaticHistory()
      : historyService.createBrowserHistory();

    const serializedState = serializedStateManager.getSerializedState();

    if (serializedState && !memoryStore.getState()) {
      memoryStore.setState(JSON.parse(serializedState));
    }

    serializedStateManager.register(() =>
      JSON.stringify(memoryStore.getState()),
    );

    const StyledVersionContainer: any = styled.div`
      display: none;
    `;

    return {
      render: () => (
        <HistoryContext.Provider value={history}>
          <FeatureAppEnvironmentContext.Provider value={env}>
            <FeatureServicesContext.Provider value={featureServices}>
              <StoryStreamContextProvider>
                <ThemeProvider theme="main">
                  <StyledVersionContainer>
                    Feature App Version :: Story Stream :: {__VERSION__}
                  </StyledVersionContainer>
                  <App />
                </ThemeProvider>
              </StoryStreamContextProvider>
            </FeatureServicesContext.Provider>
          </FeatureAppEnvironmentContext.Provider>
        </HistoryContext.Provider>
      ),
    };
  },
};

export default featureAppDefinition;
